import getLayoutProps from '../shared/getLayoutProps';
import LocationHead from '../shared/LocationHead';
import MetaHead from '../shared/MetaHead';
import StaticPage from '../components/StaticPage';
import Error from '../components/Error';

export default function Error404(props) {
  return (
    <>
      <MetaHead { ...props } />
      <LocationHead
        title="404 | Not Found"
      />
      <StaticPage>
        <Error
          code={ 404 }
          message="this page could not be found"
        />
      </StaticPage>
    </>
  );
};

export const getStaticProps = getLayoutProps;